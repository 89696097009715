export const languages = [
  {
    value: 'da',
    label: 'Danish',
    icon: 'dk',
    culture: 'dk-DK',
  },
  {
    value: 'de',
    label: 'Deutsch',
    icon: 'de',
    culture: 'de-DE',
  },
  {
    value: 'en',
    label: 'English',
    icon: 'gb',
    culture: 'en-EN',
  },
  {
    value: 'es',
    label: 'Español',
    icon: 'es',
    culture: 'es-ES',
  },
  {
    value: 'fr',
    label: 'Français',
    icon: 'fr',
    culture: 'fr-FR',
  },
  {
    value: 'it',
    label: 'Italiano',
    icon: 'it',
    culture: 'it-IT',
  },
  {
    value: 'nl',
    label: 'Nederlands',
    icon: 'nl',
    culture: 'nl-NL',
  },
];

export function setLanguage(this: any, language: any) {
  this.translationService.setLanguage(language);
  sessionStorage.setItem('language', language);
}

export function setCulture(): string {
  // find current language from array of languages, return to user "culture" of current language
  const objLanguage = languages.find(
    (el) => el.value === sessionStorage.getItem('language')
  );
  return objLanguage?.culture ?? 'en-EN';
}
